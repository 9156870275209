<template>
	<div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <p class="text-h5 mb-4">Company Bank Management</p>
                <p class="text-title mb-0">You do not have permission to view this company's all banks</p>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="text-right">
                <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon v-bind="attrs" v-on="on" @click="back"><v-icon>mdi-arrow-left</v-icon></v-btn></template><span>Back</span></v-tooltip>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <p class="text-h5 mb-0" v-text="object.name"></p>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="text-right">
                                <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon v-bind="attrs" v-on="on" @click="back"><v-icon>mdi-arrow-left</v-icon></v-btn></template><span>Back</span></v-tooltip>
                                <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon v-bind="attrs" v-on="on" @click="get"><v-icon>mdi-refresh</v-icon></v-btn></template><span>Refresh</span></v-tooltip>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="permissions.can_add">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='openCreateDialog'>
                        <v-icon small left>mdi-plus</v-icon> Create Bank
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0">
                        <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="30" :headers="headers" :items="items">
                            <template v-slot:item='{ item, index }'>
                                <tr>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.bank.code"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.bank.name"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.branch_code"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.account_number"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.reference_number"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-menu bottom offset-y v-if="hasMenu(item)">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item @click="openUpdateDialog(index, item)" v-if="item.can_edit">
                                                <v-list-item-title>Update Bank</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="openDeleteDialog(item)" v-if="item.can_delete">
                                                <v-list-item-title>Delete Bank</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-sheet>
                </v-col>
            </v-row>
        </template>
		<create-dialog ref="createDialog" :banks="banks" @created="updateItems"></create-dialog>
		<update-dialog ref="updateDialog" @updated="updateItem"></update-dialog>
		<delete-dialog ref="deleteDialog" @deleted="updateItems"></delete-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import CompanyBankCreateDialog from './CompanyBankCreateDialog.vue'
import CompanyBankUpdateDialog from './CompanyBankUpdateDialog.vue'
import CompanyBankDeleteDialog from './CompanyBankDeleteDialog.vue'
import MessageNotifier from '../../../shared/MessageNotifier.vue'

export default {
	name: 'CompanyBankList',
    components: {
        createDialog: CompanyBankCreateDialog,
        updateDialog: CompanyBankUpdateDialog,
        deleteDialog: CompanyBankDeleteDialog,
        messageNotifier: MessageNotifier,
    },
    data () {
        return {
            loading: false,
            selectedIndex: -1,
            filters: {
                company: this.$route.params.id
            },
            object: {
                name: ''
            },
            permissions: {
                can_list: false,
                can_add: false,
            },
            banks: [],
            items: [],
            headers: []
        }
    },
    computed: {
        createDialog() {
            return this.$refs.createDialog
        },
        updateDialog() {
            return this.$refs.updateDialog
        },
        deleteDialog() {
            return this.$refs.deleteDialog
        },
		notifier() {
            return this.$refs.notifier
        }
    },
	mounted: function() {
		this.get()
	},
	methods: {
        back: function(){
            this.$router.replace('/epanel/company')
        },
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/company/getCompanyBanks', this.filters).then((response) => {
				this.banks = response.data.banks
				this.items = response.data.items
				this.headers = response.data.headers
				this.permissions = response.data.permissions
				this.object = response.data.object
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { company: this.$route.params.id }
		},
        openCreateDialog: function() {
            this.createDialog.open()
        },
        openUpdateDialog: function(index, item) {
            if(item.can_edit){
                this.selectedIndex = index
                const object = {bank: item.id, branch_code: item.branch_code, account_number: item.account_number, 
                                reference_number:item.reference_number}
                this.updateDialog.updateObject(object)
                this.updateDialog.open()
            }
        },
        openDeleteDialog: function(item) {
            if(item.can_delete){
                this.deleteDialog.updateObject({bank:item.id})
                this.deleteDialog.open()
            }
        },
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        updateItems: function(item) {
            this.get()
            this.openNotifier(item.message)
        },
        updateItem: function(item) {
            this.$set(this.items, this.selectedIndex, item)
            this.openNotifier(item.message)
        },
        hasMenu: function(item) {
            return item.can_edit || item.can_delete
        }
    }
}

</script>