var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading && !_vm.permissions.can_list)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('p',{staticClass:"text-h5 mb-4"},[_vm._v("Company Bank Management")]),_c('p',{staticClass:"text-title mb-0"},[_vm._v("You do not have permission to view this company's all banks")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.back}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}],null,false,3143309572)},[_c('span',[_vm._v("Back")])])],1)],1):_vm._e(),(_vm.permissions.can_list)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-sheet',{staticClass:"pa-0",attrs:{"color":"white","elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('p',{staticClass:"text-h5 mb-0",domProps:{"textContent":_vm._s(_vm.object.name)}})]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.back}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}],null,false,3143309572)},[_c('span',[_vm._v("Back")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.get}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,317710411)},[_c('span',[_vm._v("Refresh")])])],1)],1)],1)],1)],1),(_vm.permissions.can_add)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-btn',{staticClass:"mr-2 elevation-0",attrs:{"small":"","color":"primary","ripple":false},on:{"click":_vm.openCreateDialog}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Bank ")],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-sheet',{attrs:{"color":"white","elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","loading-text":"Loading... Please wait...","no-data-text":"No data available yet","loading":_vm.loading,"items-per-page":30,"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(index + 1)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.bank.code)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.bank.name)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.branch_code)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.account_number)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.reference_number)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center"},[(_vm.hasMenu(item))?_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(item.can_edit)?_c('v-list-item',{on:{"click":function($event){return _vm.openUpdateDialog(index, item)}}},[_c('v-list-item-title',[_vm._v("Update Bank")])],1):_vm._e(),(item.can_delete)?_c('v-list-item',{on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_c('v-list-item-title',[_vm._v("Delete Bank")])],1):_vm._e()],1)],1):_vm._e()],1)])]}}],null,false,2398188127)})],1)],1)],1)]:_vm._e(),_c('create-dialog',{ref:"createDialog",attrs:{"banks":_vm.banks},on:{"created":_vm.updateItems}}),_c('update-dialog',{ref:"updateDialog",on:{"updated":_vm.updateItem}}),_c('delete-dialog',{ref:"deleteDialog",on:{"deleted":_vm.updateItems}}),_c('message-notifier',{ref:"notifier"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }