<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title>
					<span class="text-h5 mb-3">Create Company Bank</span>
				</v-card-title>
				<v-card-text>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-0" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-autocomplete dense label="Bank*" :items="banks" :rules="rules.bank" :error-messages="errors.bank" v-model="object.bank"></v-autocomplete>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Branch Code" :rules="rules.branch_code" :error-messages="errors.branch_code" v-model="object.branch_code"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Account Number*" :rules="rules.account_number" :error-messages="errors.account_number" v-model="object.account_number"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="isDBS()">
							<v-text-field dense label="DBS ID" :rules="rules.reference_number" :error-messages="errors.reference_number" v-model="object.reference_number"></v-text-field>
						</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="create">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
	name: 'CompanyBankCreateDialog',
    props: {
        banks: {
            type: Array,
            required: true,
            default: () => []
        }
    },
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                company: this.$route.params['id'],
                bank: '',
                branch_code: '',
                account_number: '',
                reference_number: ''
			},
			rules: {
                bank: [
                    (value) => !!value || 'Bank is required',
                ],
                branch_code: [
                    (value) => {
                        if(!value) {
                            return 'Branch code is required'
                        }

                        return true
                    }
                ],
                account_number: [
                    (value) => {
                        if(!value) {
                            return 'Account number is required'
                        }

                        if(this.object.bank){
                            const banks = this.banks.filter(item => item.value === this.object.bank)

                            if(banks.length > 0){
                                const bank = banks[0]
                                if(bank.account_digits.length > 0 && !bank.account_digits.includes(value.length.toString())){
                                    return 'Account length must be ' + bank.account_digits.join(' or ')
                                }
                            }
                        }

                        return true
                    }
                ],
                reference_number: [
                    (value) => {
                        if(this.isDBS()){
                            if(!value) {
                                return "DBS ID is required"
                            }

                            if(value.length != 8){
                                return "DBS ID is 8 characters"
                            }

                        }
                        return true
                    }
                ]
			},
			errors: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
            this.loading = false
            this.object = { company: this.$route.params['id'], bank: '', branch_code: '', account_number: '', reference_number: '' }
            this.errors = {}
            this.form.reset()
			this.form.resetValidation()
		},
		create: function() {
			if(this.form.validate()){
				this.loading = true
                const object = copyObject(this.object)

				this.$store.dispatch('epanel/company/createCompanyBank', object).then((response) => {
                    this.$emit('created', {object: response.data.object, message: response.message})
                    this.close()
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
			}
		},
        isDBS: function() {
            if(this.object.bank){
                const bank = this.banks.filter((item) => item.value === this.object.bank)[0]
                return bank.code.toLowerCase().indexOf('dbs') > -1
            }
        }
	}
}

</script>